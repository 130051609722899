.drawer-panel {
  transition: width 0.3s ease, height 0.3s ease;
  display: flex;
}

.drawer-content {
  padding: 1rem 2.5rem 1rem 0rem;
  display: flex;
  flex: 1;
}

.drawer-root {
  position: absolute;
}

.drawer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.drawer > div {
  flex: 1;
}
