.avatarModal__container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 0.5rem;
  column-gap: 1rem;
}

.avatarModal__item {
  cursor: pointer;
}
.avatarModal__item:hover {
  opacity: 0.8;
}
