.gameTabEditView {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding-right: 1rem;
  /* height: calc(100vh - 8rem); */
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .gameTabEditView {
    width: calc(100vw - 3rem);
  }
}
