.mapHex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 1rem;
}

@media (max-width: 768px) {
  .mapHex {
    padding-right: 0;
  }
}

.map {
  /* paper like */
  background-color: rgba(15, 15, 15, 0.85);
  border-radius: 8px;
  /* paper like */

  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 12rem); */
  flex: 1;
  position: relative;
}

.map__background {
  background-image: url("../..//assets/maps/battlemap.jpeg");
}

.map__fog {
  top: 0;
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
}

.map__container {
  position: relative;
  width: 0;
  height: 0;
}

.map__toolbar {
  top: 8px;
  position: absolute;
  background-color: rgba(15, 15, 15, 0.85);
  border-radius: 8px;
  z-index: 10;
}

.map__container-inner {
  top: -17px;
  position: absolute;
  background-repeat: no-repeat;
}

.map__drawing-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
