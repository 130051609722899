.user_area__container {
  position: relative;
  min-width: 200px;
  min-height: 200px;
  border: 2px solid;
  border-radius: 8px;
}

.user_area__label {
  position: absolute;
  bottom: -28px;
  left: 24px;
}

.user_area__action {
  padding: 0.25rem;
  position: absolute;
  top: -2rem;
  min-height: unset;
}

.user_area__resize {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  cursor: se-resize;
  background: rgba(0, 0, 0, 0.7);
}
