.avatar__container {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  bottom: 1rem;
  right: 1rem;
  width: 72px;
  height: 72px;
  z-index: 10;
}
@media (max-width: 1024px) {
  .avatar__container {
    bottom: 4rem;
  }
}

.avatar__image--container {
  position: absolute;
  transition: 1s;
  bottom: 0px;
}

.avatar__image--container:hover {
  cursor: pointer;
}

.avatar__image {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  border: 2px solid red;
}

.avatar__image:hover {
  /* filter: grayscale(); */
}

.avatar__image--me {
  width: 8px;
  height: 8px;
  background-color: chartreuse;
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
}

.avatar__image--round {
  position: absolute;
  bottom: 4px;
  right: 16px;
  color: #7fff00;
}
.avatar__image--container:hover .avatar__image--round {
  background: rgba(0, 0, 0, 0.7);
}

.avatar__image--round > svg {
  font-size: 16px;
}

.avatar__image--cards {
  position: absolute;
  top: 4px;
  right: 8px;
  color: chartreuse;
}

.avatar__image--container:hover .avatar__image--cards {
  background: rgba(0, 0, 0, 0.7);
}

.avatar__image--cards > svg {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
