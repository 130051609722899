.actionsController {
  display: flex;
  overflow-y: scroll;
  padding: 0.5rem;
}
.actionsController_container {
  display: flex;
  gap: 1rem;
  min-height: min-content; /* needs vendor prefixes */
}

.actionsController_action {
  height: 151px;
  width: 240px;
  border: 1px solid white;
  border-radius: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.actionsController_textField {
    display: flex;
    width: 240px;
}
