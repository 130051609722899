.toast-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.toast {
  background-color: rgba(15, 15, 15);
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
}

.toast button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.toast-info {
}

.toast-error {
  background-color: rgba(244, 67, 54);
  border: 1px solid rgba(244, 67, 54, 0.5);
}
