/* Global print settings */
@page {
  size: A4;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}
.print-container{
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.print-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  align-content: start;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr); */
  /* gap: 8mm; */
  padding: 8mm;
  width: 210mm;
  height: 297mm;
  box-sizing: border-box;
  position: absolute;
  /* top: -9999px; */
  /* visibility: hidden; */
  background-color: white;
}

.print-layout__card {
  width: 64mm;
  height: 89mm;
  position: relative;
  background-size: cover;
  background-position: center;
  border: 1px dashed white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  background-color: black;
}

.print-layout__card-content {
  z-index: 1;

  width: 100%;
  position: relative;
  background-size: cover;
  height: 100%;
}

.print-layout__card-title {
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.print-layout__card-tokens {
  max-height: 100%;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
}

.print-layout__card-token {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid white;
}

/* Print-specific styles */
@media print {
  .print-layout {
    position: static;
    visibility: visible;
  }
  .print-hidden {
    visibility: hidden;
    height: 0;
  }
}
