.gameSchemaMenu {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.gameSchemaMenu__char {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.gameSchemaMenu__noMenuItem {
  display: flex;
  padding-top: 1rem;
}

.gameSchemaMenu__noMenuItem button {
  flex: 1;
}


