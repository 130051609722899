.card-generator::-webkit-scrollbar {
  display: none;
}
.card-generator .avatar__image {
  width: 230px;
  height: 330px;
  object-fit: cover;
}

/*
you need to disable margin height & overflow on page
800 * 1150
*/

.card-generator .avatarModal__item {
  position: relative;
}
.card-generator-container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  border: 1px dashed black;
  gap: 1rem;
  justify-content: space-between;
}

.card-generator-operators {
  position: absolute;
  bottom: 40px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.operator-heart {
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-image: url("https://cuni.fra1.cdn.digitaloceanspaces.com/cuni_cards/heart.webp");
}

.card-generator-frame {
  position: absolute;
  background-image: url("https://cuni.fra1.cdn.digitaloceanspaces.com/cuni_cards/frame.png");
  width: 100%;
  height: 100%;
  /* z-index: 20; */
  background-size: contain;
  border-radius: 8px;
}

.card-generator-count {
  position: absolute;
  right: 40px;
  top: 40px;
  color: white;
  font-size: 20px;
  background: black;
}
