.fullscreen-div {
}

.fullscreen-div.fullscreen {
  background-color: gray;
  position: fixed;
  padding: 1rem;
  width: calc(100vw - 2rem);
  height: calc(100vh - 2rem);
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's on top */
}

.fullscreen-left-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
