.deck-place {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 240px;
  border-radius: 0.5rem;
  border: 2px dashed white;
  box-shadow: 3px 3px 2px 1px #00000033;
  z-index: 200;
  background-size: cover;
  flex-direction: column;
}

.deck-place:hover {
  border-color: chartreuse;
  border-style: solid;
  border-width: 2px;
}

.drag-parent {
  width: 100px;
  height: 100px;
}
.drag-handle {
  position: absolute;
  background-color: chartreuse;
  width: 100px;
  height: 100px;
}
.drag-handle:hover {
  background-color: white;
}

.deck-place-container {
  display: flex;
  gap: 0.25rem;
}

.deck-place-in-action {
  padding: 0.25rem; 
  position: absolute;
  top: -2rem;
  min-height: unset;
}

.deck-place-hover {
  display: none;
}
.deck-place:hover .deck-place-hover {
  display: flex;
  color: chartreuse;
  background: rgba(0, 0, 0, 0.7);
}

.deck-place-multi-v {
  position: absolute;
  top: 10px;
  right: -12px;
  width: 3px;
  height: 100%;
  background-color: white;
  border-right: 1px solid gray;
  border-radius: 8px;
}

.deck-place-multi-h {
  position: absolute;
  left: 10px;
  bottom: -12px;
  width: 100%;
  height: 3px;
  background-color: white;
  border-bottom: 1px solid gray;
  border-radius: 8px;
}
