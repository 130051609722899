.card-view {
  border: 2px solid white;
  width: 150px;
  height: 225px;
  border-radius: 8px;
  cursor: grab;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.uiType-CARD {
}

.uiType-ROUND {
  border-radius: 50% !important;
  height: 150px;
  background-size: cover;
}

.uiType-SQUARE {
  height: 150px;
  background-size: cover;
}

.uiType-big-ROUND {
  border-radius: 50% !important;
  height: 150px;
  background-size: cover;
}
.uiType-big-SQUARE {
  height: 150px;
  background-size: cover;
}

.uiType-big-FREE {
  border: unset !important;
  box-shadow: unset !important;
}

.card-index {
  display: none;
}

.card-view:hover .card-index {
  display: block;
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: chartreuse;
}

.card-tokens-container {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
}

.card-tokens-item {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: contain;
  border: 1px solid white;
}
