body {
  /* TODO: make background choosable */
  /* background-image: url("assets/images/hogwarts.png"); */
  background-image: url("assets/images/cuni-dark.webp");
  /* background-image: url("assets/images/forest.jpeg"); */
  /* background: darkslateblue; */
  background-size: cover;
  font-family: cuni-fantasy;
}

@font-face {
  font-family: "cuni-fantasy";
  src: url("assets/fonts/vinque/rpg.otf") format("opentype");
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

.page {
  height: calc(100vh - 4rem);
}

.App {
  /* text-align: center; */
  display: grid;
  padding: 2rem;
  /* grid-template-columns: 2fr 3fr 2fr; /* this has to be  calculated*/
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  /* grid-template-columns: repeat(auto-fit, 1fr); */
  /* minus 2*2rem margin minus 4rem navbar*/
  height: calc(100vh - 8rem);
  gap: 1rem;
  overflow: hidden;
}
.app-columns {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1rem;
}
.app-columns > div {
  flex: 1;
  overflow: auto;
}
.App > div:nth-child(1) {
  grid-column: span 2;
}
.App > div:nth-child(2) {
  grid-column: span 5;
}
.App > div:nth-child(3) {
  grid-column: span 2;
}
@media (max-width: 1024px) {
  .App {
    /* 60px is mobile toolbar*/
    height: calc(100vh - 6rem - 64px);
    padding-bottom: 4rem;
  }
  .app__block {
    width: calc(100vw - 4rem);
  }
}

@media (max-width: 768px) {
  .App {
    /* 60px is mobile toolbar*/
    height: calc(100vh - 6rem - 56px);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 600px) {
  .App {
    /* 60px is mobile toolbar*/
    height: calc(100vh - 5rem - 56px);
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}

.app__block {
  color: white;
}
.app__chat {
  height: 100%;
  flex: 1;
  /* overflow-y: scroll; */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-right: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app__chat::-webkit-scrollbar {
  display: none;
}

.border {
  border-radius: 8px;
}
.paper-outline {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.paper-ish {
  background-color: rgba(15, 15, 15, 0.85);
  color: white;
  overflow-y: auto;
}

.modal {
  width: 520px;
  max-width: calc(100vw - 2rem);
  color: white;
  background: black;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex: 1;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.column::-webkit-scrollbar {
  display: none;
}

.column-fixed {
  flex-shrink: 0;
}

.paper {
  /* TODO: this is my defaults*/
  background-color: rgba(15, 15, 15, 0.85);
  color: white;
  border-radius: 8px;
  padding: 1rem;
  min-height: 40px;
  overflow-y: auto;
}
.paper-sm {
  padding: 0.5rem;
}
.paper-0 {
  padding: 0;
}

.paper__menu--handler {
  position: relative;
}

.paper__menu {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background-color: rgba(15, 15, 15, 0.95);
  border-radius: 8px;
}

.flex {
  display: flex;
}
.flex-gap {
  display: flex;
  gap: 0.5rem;
}

.paper__tab {
  padding: 0;
  min-height: 48px;
}

.paper__tab-0 {
  min-height: unset;
}

.paper__tab button {
  color: white;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  transition: background-color 0s 600000s, color 0s 600000s;
}

.error-page {
  color: white;
  max-width: 80%;
  max-width: 80%;
  transform: translate(10%, 50%);
}

.game-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.game-info-inner {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.support {
  color: white;
  position: fixed;
  bottom: 8px;
  left: 2rem;
  display: flex;
  gap: 1rem;
  z-index: 200;
}
@media (max-width: 600px) {
  .support {
    left: 0;

    background-color: black;
    border-radius: 8px;
    padding: 1rem;
  }
}

.support a {
  color: white;
}
a {
  color: white;
}

.support a:hover {
  opacity: 0.8;
}
a:hover {
  opacity: 0.8;
}

.no-events > * {
  pointer-events: none;
}

.overflow-visible {
  overflow: visible;
}

.modal-max-height {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

.player-round {
  border: 2px solid chartreuse;
}

.navigate-back {
  background-color: rgba(15, 15, 15, 0.85);
  color: white;
  border-radius: 8px;
  top: 70px;
  right: -8px;
  display: flex;
  flex-direction: column;
}

.designing-view {
  display: flex;
  gap: 1rem;
}

.designing-view-left {
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
}

.designing-view-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
