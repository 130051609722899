.game-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.game-item-wrapper {
  height: fit-content;
  width: fit-content;
}

.game-item {
  background-size: cover;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  position: relative;
}
.game-item-buttons {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
}

@media (max-width: 600px) {
  .game-item {
    background-color: lightblue;
    width: auto;
    flex: 1;
  }

  .game-item-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.game-wallpaper {
  background-size: cover;
}

