.char__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  /* height: calc(100vh - 8rem); */
  overflow-y: auto;
}

.char__container-grid  > *{
 /* flex: 1 1 50%;  */
 /* Each child takes up 50% of the width */
 /* flex-basis: 49%; */
 /* width: 49; */
}


.char__container-grid{
  /* flex-direction: row;
  flex-wrap: wrap; */
  /* grid-template-columns: 1fr 1fr;
  display: grid; */
  /* flex-wrap: wrap;
  flex-direction: row; */

  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-auto-flow: dense; */
}