.genericCharacter__EditMode:hover {
  font-weight: bold;
  cursor: pointer;
}

.genericCharacter__EditMode {
  position: relative;
  overflow: visible;
}

.genericCharacter__EditMode-toolbox {
  position: absolute;
  display: none;
  width: fit-content;
  z-index: 200;
  transform: translate(-20%, 25%);
}

.genericCharacter__EditMode:hover .genericCharacter__EditMode-toolbox {
  display: flex;
}
