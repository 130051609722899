.grant-permission {
  position: fixed;
  bottom: 1rem;
  left: calc(50% - 160px);
  width: 320px;
}

.grant-permission-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.grant-permission-text {
  display: flex;
  justify-content: center;
}
