.storymodal__list {
  list-style: none;
  padding: 0;
  max-height: 70vh;
  overflow-y: scroll;
}

.storymodal__list-item {
  background: #444;
  margin: 10px 0;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
}

.storymodal__title {
  color: #61dafb;
}
