.table__container {
  display: grid;
}

.table__container-card {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.table__headerCell {
  font-weight: bold;
  border-bottom: 1px solid white;
  margin-bottom: 0.25rem;
}

.table__cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
