.shared__token-picker {
  gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.shared__token-button {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
}

.shared__token-button:hover {
  border: 1px solid white;
  cursor: pointer;
}

.shared__token-root {
  max-height: 250px;
}
