.square {
  opacity: 1;
  width: 80px;
  height: 80px;
}

.square:hover {
  opacity: 0.7;
  cursor: pointer;
}
.square-middle {
  width: 80px;
  height: 80px;
  background: #f44336;
}

.square-row {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
}

.hex {
  opacity: 1;
  float: left;
  margin-right: -26px;
  margin-bottom: -50px;
}
.hex .hex-left {
  opacity: 1;
  float: left;
  width: 0;
  border-right: 30px solid #f44336;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
}
.hex .hex-middle {
  opacity: 1;
  float: left;
  width: 60px;
  height: 104px;
  background: #f44336;

  display: flex;
  padding: 0.5e;
  flex-wrap: wrap;
}
.hex .hex-right {
  opacity: 1;
  float: left;
  width: 0;
  border-left: 30px solid #f44336;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
}
.hex-row {
  clear: left;
}
.hex.even {
  margin-top: 53px;
}

.exist .hex-right {
  border-left-color: rgba(15, 15, 15, 0.85);
}

.exist .hex-left {
  border-right-color: rgba(15, 15, 15, 0.85);
}

.exist .hex-middle {
  background: rgba(15, 15, 15, 0.85);
}

/* .empty:hover .hex-right {
  border-left-color: blue;
}

.empty:hover .hex-left {
  border-right-color: blue;
}

.empty:hover .hex-middle {
  background: blue;
} */

.hex:hover {
  opacity: 0.7;
}
.hex:hover .hex-right {
  /* opacity: 0.7; */
  cursor: pointer;
}

.hex:hover .hex-left {
  /* opacity: 0.7; */
  cursor: pointer;
}

.hex:hover .hex-middle {
  /* opacity: 0.7; */
  cursor: pointer;
}

.hex-token {
  color: white;
  padding: 2px;
}

.hex-token-button {
  max-width: 24px;
  max-height: 24px;
}

