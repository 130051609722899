.table__row__menu {
  position: absolute; /* Make the item absolutely positioned */
  right: -1rem; /* Adjust as needed */
  height: 100%;
  display: none;
  z-index: 10;
  min-height: 34px;
}

.table__row {
  display: grid;
  position: relative;
  grid-column: 1/-1;
}

.table__row:hover .table__row__menu {
  display: flex;
}

.table__row-selected{
  background-color: rgba(255, 255, 255, 0.25);
}