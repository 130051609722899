.chat__wrapper {
  height: 100%;
  gap: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat__message {
  display: flex;
  gap: 0.5rem;
}

.chat__window {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.chat__message--name {
  margin-right: 2px;
}

.chat__message--session {
  justify-content: center;
  text-align: center;
  color: white;
  flex-direction: column;
}
.chat__message--session h3 {
  margin-block-end: 0;
}

.chat__message--session hr {
  width: 100%;
  margin: 0.25rem;
}

.chat__input {
  background-color: lightgray;
  border: 4px ridge;
  padding: 4px;
}

.chat__input--form {
  display: flex;
  width: 100%;
}

.chat__input--message {
  flex: auto;
}

.chat__panel {
  display: flex;
}

.chat__input--container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.chat__toolbar {
  display: flex;
  justify-content: space-between;
}
.chat__toolbar-section {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 1024px) {
  .chat__toolbar {
    flex-direction: column;
  }
}
