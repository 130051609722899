.page {
  margin: 2rem;
  height: calc(100vh - 8rem);
  overflow: auto;
}

.side-bar-page {
  display: grid;
  grid-template-columns: 320px auto;
  gap: 2rem;
}

.payments {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.payments > div {
  max-width: 360px;
  min-width: 200px;
  height: 560px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  flex: 1;
}

.payments-item {
  flex-direction: column;
  display: flex;
  gap: 1rem;
}

.payments-text {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.document-section {
  margin-top: 20px;
}
