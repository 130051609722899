.board-game-template {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
}

.canvas-container {
  overflow: auto;
  height: 580px;
  width: 100%;
}

.canvas {
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.box {
  background-color: rgba(0, 123, 255, 0.167);
  border: 1px solid white;
}
.label {
  background-color: transparent;
  border: none;
}

.label-box {
  height: 100%;
  width: 100%;
}
.rotation-controls {
  position: absolute;
  top: -30px;
  left: 0;
  display: flex;
  gap: 4px;
}
