.card-panel-view{
    min-height: 2rem;
    border: 3px dashed white;
    border-radius: 0.5rem;
    margin-top: 0.5rem;

    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 4px;
}