.dice {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .dot-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
    height: 80%;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 5px rgb(0 0 0 / 20%);
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
  }
  
  .dot-1 .dot:nth-child(1) {
    grid-column: 2;
    grid-row: 2;
  }
  
  .dot-2 .dot:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dot-2 .dot:nth-child(2) {
    grid-column: 3;
    grid-row: 3;
  }
  
  .dot-3 .dot:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dot-3 .dot:nth-child(2) {
    grid-column: 2;
    grid-row: 2;
  }
  
  .dot-3 .dot:nth-child(3) {
    grid-column: 3;
    grid-row: 3;
  }
  
  .dot-4 .dot:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dot-4 .dot:nth-child(2) {
    grid-column: 1;
    grid-row: 3;
  }
  
  .dot-4 .dot:nth-child(3) {
    grid-column: 3;
    grid-row: 1;
  }
  
  .dot-4 .dot:nth-child(4) {
    grid-column: 3;
    grid-row: 3;
  }
  
  .dot-5 .dot:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dot-5 .dot:nth-child(2) {
    grid-column: 1;
    grid-row: 3;
  }
  
  .dot-5 .dot:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }
  
  .dot-5 .dot:nth-child(4) {
    grid-column: 3;
    grid-row: 1;
  }
  
  .dot-5 .dot:nth-child(5) {
    grid-column: 3;
    grid-row: 3;
  }
  
  .dot-6 .dot:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  .dot-6 .dot:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  
  .dot-6 .dot:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }
  
  .dot-6 .dot:nth-child(4) {
    grid-column: 3;
    grid-row: 1;
  }
  
  .dot-6 .dot:nth-child(5) {
    grid-column: 3;
    grid-row: 2;
  }
  
  .dot-6 .dot:nth-child(6) {
    grid-column: 3;
    grid-row: 3;
  }
  
  @media (max-width: 800px) {
    .dice {
      width: 60px;
      height: 60px;
    }
  
    .dot {
      width: 12px;
      height: 12px;
    }
    .dot-container {
      gap: 6px;
    }
  }
  
  .dice:hover {
      opacity: 0.8;
  }
  
  .dice-disabled:hover {
    cursor: not-allowed;
  }
  
  .dice-snail {
      font-size: 40px;
  }