.shared__color-picker {
  gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.shared__color-button {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  border: 1px solid gray;
}

.shared__color-button:hover {
  border: 1px solid white;
  cursor: pointer;
}

.diagonal-line {
  width: 24px;
  height: 22px;
  border-left: 2px solid red; /* Left border */
  transform: rotate(-45deg);
  position: absolute;
  top: -9px;
  left: 6.5px;
}
