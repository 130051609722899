.token-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 200;
  background-size: cover;
}

.token-link:hover {
  border-color: chartreuse;
  border-style: solid;
}

.token-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
