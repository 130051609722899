.template-panel-view {
  min-height: 44px;
  min-width: 100px;
  border: 3px dashed white;
  border-radius: 0.5rem;
  margin-top: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 4px;
}

.board-game-template-view__container {
  background-size: cover;
  position: relative;
}
.board-game-template-view__handler {
  position: absolute;
  background-color: rgba(0, 123, 255, 0.167);
  border: 1px solid white;
}
.board-game-template-view__handler:hover {
  background-color: rgba(0, 123, 255, 0.267);
}
